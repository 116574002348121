<template>
  <div id="video-controls" :class="{'mobile': $vuetify.breakpoint.smAndDown}">
    <v-toolbar color="grey lighten-2" :class="{'justify-space-between': $vuetify.breakpoint.smAndDown}">
      <v-dialog v-model="dialog" width="500"
          >
            <template v-slot:activator="{ on }">
              <v-btn text color="secondary darken-1" dark v-on="on" v-if="$vuetify.breakpoint.mdAndUp"
              >
                <v-icon>keyboard</v-icon> shortcuts
              </v-btn>
            </template>

            <v-card>
              <v-card-title
                class="headline gradientBG white--text"
                primary-title
              >
                Keyboard Controls
              </v-card-title>

              <v-card-text class="py-4">
                <h2 class="grey--text mb-2">Basics</h2>
                <h3 class="primary--text">Spacebar</h3>
                <p>Press the spacebar to <strong>Play</strong> or <strong>Pause</strong> the video.</p>

                <h2 class="grey--text mb-2">Manual Time Input</h2>
                <p>You can type <strong>hours</strong>, <strong>minutes</strong>, and <strong>seconds</strong> into the field using this format:
                  <strong>"hh:mm:ss"</strong>. EXAMPLE: <i>"00:04:05"</i> or <i>"4:05"</i></p>

                <h3 class="primary--text">Enter</h3>
                <p>Pressing <strong>Enter</strong> update the video to the time in the input field.</p>
                <h3 class="primary--text">Keyboard &#8593; / &#8595;</h3>
                <p>This will increment the <strong>minutes</strong> time parameter by <strong>1</strong> second.</p>
                <h3 class="primary--text">Shift + Keyboard &#8593; / &#8595;</h3>
                <p>This will increment the <strong>minutes</strong> time parameter by <strong>10</strong> seconds.</p>
                <h3 class="primary--text">Alt/Option + Keyboard &#8593; / &#8595;</h3>
                <p>This will increment the <strong>seconds</strong> time parameter by <strong>1</strong> minute.</p>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary darken-2"  @click="dialog = false">
                  Got it, thanks
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-dialog>



      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>



      <div class="time-input pa-0  mr-2">
        <v-text-field
        v-model="time"
        placeholder="00:00"
        filled
        dense
        type="text"
        @keydown="countTime"
        @focus="selectAll"
        v-mask="'##:##:##'"
        ref="timer"
        ></v-text-field>
      </div>

      <div class="mr-4 total">
          <span class="grey--text mr-1">/</span>  {{total}}
      </div>
      <!-- @change="updateVideoTime" -->
      <div id="add-step-button">
        <v-btn class="add-step mr-4 ml-2" :dark="time != '00:00:00'" @click="createStep" :disabled="time == '00:00:00'" :class="{'gradientBG': time != '00:00:00', 'grey darken-2': time == '00:00:00'}" v-if="$vuetify.breakpoint.mdAndUp">
          <v-icon color="white" class="mr-2">library_add</v-icon>
          add Step
        </v-btn>
        <v-btn class="add-step ma-0 px-2" :dark="time != '00:00:00'" @click="createStep" :disabled="time == '00:00:00'" :class="{'gradientBG': time != '00:00:00', 'grey darken-2': time == '00:00:00'}" v-else>
          <v-icon color="white" class="mr-2">library_add</v-icon>
          Step
        </v-btn>
      </div>

    </v-toolbar>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      dark
    >
      {{ snackbar.message }}
      <v-btn
        text
        color="white"
        @click="snackbar.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>


  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import {mask} from 'vue-the-mask'
export default {
  name: "video-controls",
  props: ['timeIn', 'duration'],
  directives: {mask},
  computed:{
    ...mapGetters(['GET_BUILD_VIDEO']),
  },
  data(){
    return{
      currentIndex: 0,
      time: "00:00:00",
      dialog: false,
      total: null,
      snackbar: {'show':false},
      newVideo: {},
    }
  },
  watch:{
    timeIn:function(newV,oldV){
      if(newV.timeupdate !== undefined){
        // process.env.NODE_ENV === "development" ? console.log( 'timeIn', newV.timeupdate, oldV.timeupdate, this.time ) : null;
        let cleanSeconds =  Math.floor(newV.timeupdate|0);
        // let newTime = this.$moment().seconds(cleanSeconds).format('HH:mm:ss')
        let newTime = this.$getTimeFormat(cleanSeconds);
        // process.env.NODE_ENV === "development" ? console.log( 'time in moment', newTime ) : null;
        this.time = newTime;
      }
    }
  },
  methods: {
    selectAll(e){
      // console.log('selected', e.target);
      e.target.select()
      // this.$refs.timer.select();
      // @focus.native="$event.target.select()"
    },
    countTime(e){
      if(e.key === 'ArrowUp'){
        e.preventDefault();
        if(e.key === 'ArrowUp' && e.shiftKey === true){
          let splitTime = this.time.slice();
          this.time = this.$moment(splitTime,'HH:mm:ss').add(10, 'seconds').format('HH:mm:ss')
        }
        else if(e.key === 'ArrowUp' && e.altKey === true){
          let splitTime = this.time.slice();
          this.time = this.$moment(splitTime,'HH:mm:ss').add(1, 'minutes').format('HH:mm:ss')
        }
        else{
          let splitTime = this.time.slice()
          this.time = this.$moment(splitTime,'HH:mm:ss').add(1, 'seconds').format('HH:mm:ss')
        }

      }
      else if(e.key === 'ArrowDown'){
        e.preventDefault();
        if(this.$moment.duration(this.time).asSeconds() > 0){
          if(e.key === 'ArrowDown' && e.shiftKey === true){
            let splitTime = this.time.slice();
            this.time = this.$moment(splitTime,'HH:mm:ss').subtract(10, 'seconds').format('HH:mm:ss')
          }
          else if(e.key === 'ArrowDown' && e.altKey === true){
            let splitTime = this.time.slice();
            this.time = this.$moment(splitTime,'HH:mm:ss').subtract(1, 'minutes').format('HH:mm:ss')
          }
          else{
            let splitTime = this.time.slice();
            this.time = this.$moment(splitTime,'HH:mm:ss').subtract(1, 'seconds').format('HH:mm:ss')
          }
        }
      }
      if(e.key === 'Enter'){
        this.updateVideoTime();
        this.$refs.timer.blur();
      }
    },

    updateVideoTime(){
      let vThis = this;
      let newTime = this.$moment.duration(this.time).asSeconds();
      let duration = this.$moment.duration(this.duration).asSeconds();
      if(newTime <= duration){
        this.$emit('updateVideoTime', newTime)
      }
      else{
        vThis.snackbar ={
          'show': true,
          'message': "That time doesn't exist!",
          'color':'error',
          'action': 'close'
        }
        vThis.snackbarTimer = setTimeout(() => {
          vThis.snackbar.show = false;
        },5000)
      }
    },
    createStep(){
      let vThis = this;
      this.buildStep().then((data)=>{
        // console.log('x', typeof data.video, data.video, data.foundIndex);
        let video = data.video
        vThis.$store.dispatch('setBuildVideo', {video}).then(()=>{
          // vThis.$emit('scrollToNewStep');
            let vThis = this;
            let stepNum = data.foundIndex >= 0 ? data.foundIndex+1 : data.video.steps.length
            let el = document.getElementById(`card_${stepNum}`);
            process.env.NODE_ENV === "development" ? console.log( 'el', el ) : null;
            vThis.$scrollTo(el, '1000', {container:'#step-builder', easing: 'ease-out', offset: -'60'});
            vThis.$emit('onStepChange', video.steps);
        });
      })
    },
    buildStep(){
      let vThis = this;
      let video = JSON.parse(JSON.stringify(this.GET_BUILD_VIDEO));
      process.env.NODE_ENV === "development" ? console.log( 'vThis video slice', typeof video, video ) : null;

      return new Promise((resolve, reject) => {
      let time = vThis.$moment.duration(vThis.time).asSeconds();
      let stepIndex = video.steps.length+1;

      function compare(a, b) {
        return a.timestamp > b.timestamp ? 1: -1
      }

      let sorted = video.steps.slice().sort(compare);

      let foundDuplicate = sorted.findIndex(el => time == el.timestamp);
      if(foundDuplicate >= 0){
       vThis.snackbar ={
         'show': true,
         'message': 'Step Already Exists!',
         'color':'error',
         'action': 'close'
       }
       vThis.snackbarTimer = setTimeout(() => {
         vThis.snackbar.show = false;
         reject();
       },5000)
      }
      else{
          let foundIndex = sorted.findIndex(el => time < el.timestamp);
          process.env.NODE_ENV === "development" ? console.log( 'foundIndex', foundIndex ) : null;
          let newItem = {'timestamp': time, 'autopause': true};
          const insert = (arr, index, newItem) => [...arr.slice(0, index),newItem,...arr.slice(index)]
          //if its in the array
          if(foundIndex >= 0){
            const result = insert(sorted, foundIndex, newItem);
            video.steps = result;
            resolve({video : video, foundIndex: foundIndex})
          }
          //if its not inthe array, add to end;
          else{
            video.steps.push(newItem);

            resolve({video : video, foundIndex: foundIndex})
          }

        }
      });
    },
    setIndex(index){
      this.currentIndex = index;
      return index
    },
    clickStep(marker){
      // console.log('clicked', marker);
      let data = []
      data.element = false;
      data.marker = marker;
        this.$emit('onStepClicked', data)
    },
    startPlaying(){
      this.$emit('startPlaying', true)
    },
  },
  mounted(){
    let duration = this.$moment.duration(this.duration);
    let hours = Math.floor(duration.asHours()|0);
    let minutes = Math.floor(duration.asMinutes()|0);
    // console.log('duration.asSeconds()', duration.asSeconds());
    let seconds = this.$moment().seconds(duration.asSeconds()).format('ss');
    this.total = `${hours}:${minutes}:${seconds}`;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#video-controls{
  position: relative;
  &.mobile{
    .time-input{
      max-width: 110px
    }
    .v-toolbar__content{
      justify-content: space-between;
    }
  }
  .add-step{
    border-radius: inherit inherit 0 0 ;
  }
  .total{
    font-size: 140%;
    opacity: .55;
  }
  .time-input{
      width: 110px;
  }
  .v-input__slot{
    margin:0;
    padding: 0 8px;
    min-height: 36px;
    .v-input__prepend-inner{
      margin-top: 8px!important;
    }
    input{
      height: 36px;
      font-size: 24px;
    }
    &:after, &:before {
    border-color: currentcolor;
    border-style: solid;
    border-width: thin 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    display: none;
    }
  }
  .v-text-field__details{
    display: none;
  }
}
</style>
